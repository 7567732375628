export const GLOBAL_AUDIO = {
  /** @type {HTMLAudioElement} */
  partyReady: null,
  /** @type {HTMLAudioElement} */
  matchReady: null,
};

// Init audio globally can help playing sound when the tab is inactive.
export const initAudio = () => {
  GLOBAL_AUDIO.partyReady = new Audio("/sounds/party_ready.mp3");
  GLOBAL_AUDIO.matchReady = new Audio("/sounds/match_start.mp3");
};

export const playPartyReady = () => {
  if (!GLOBAL_AUDIO.partyReady) {
    console.error(new Error("Party ready sound not initialized."));
    return;
  }

  GLOBAL_AUDIO.partyReady
    .play()
    .catch((err) =>
      console.error("There was an error trying to play the audio", err)
    );
};

export const playMatchReady = () => {
  if (!GLOBAL_AUDIO.matchReady) {
    console.error(new Error("Match ready sound not initialized."));
    return;
  }

  GLOBAL_AUDIO.matchReady
    .play()
    .catch((err) =>
      console.error("There was an error trying to play the audio", err)
    );
};
