import io from "socket.io-client";
import queryString from "query-string";

import { lobbiesRef } from "../services/firebase";

import { LOBBY_VISIBILITIES, LOBBY_STATUSES } from "../lib";

export const staticLobbySocket = {
  socket: null,
  lobbyUnsubscribe: null,
  lobbyChatUnsubscribe: null,
};

export const staticPublicLobbiesSocket = {
  publicLobbiesUnsubscribe: null,
};

export const initLobbySocket = (lobbyId, userUid, setSocketLobbyId) => {
  staticLobbySocket.socket = io.connect(
    `${process.env.REACT_APP_API_URL}/LOBBIES`,
    {
      query: queryString.stringify({
        lobbyId,
        userUid,
      }),
    }
  );
  staticLobbySocket.socket.on("connect", () => {
    setSocketLobbyId(lobbyId);
  });
};

export const getLobbySocket = () => {
  return staticLobbySocket.socket;
};

export const getLobbySocketSafe = () => {
  if (!staticLobbySocket.socket) {
    throw new Error(
      "Must call .initLobbySocket() before .getLobbySocketSafe()"
    );
  }

  return getLobbySocket();
};

export const initLobbySubscribe = (_lobbyUnsubscribe) => {
  staticLobbySocket.lobbyUnsubscribe = _lobbyUnsubscribe;
};
export const initLobbyChatSubscribe = (_lobbyChatUnsubscribe) => {
  staticLobbySocket.lobbyChatUnsubscribe = _lobbyChatUnsubscribe;
};

export const initPublicLobbySubscribe = (_publicLobbyUnsubscribe) => {
  staticPublicLobbiesSocket.publicLobbiesUnsubscribe = _publicLobbyUnsubscribe;
};

export const unsubscribeFromLobbyRealtime = (setSocketLobbyId) => {
  if (staticLobbySocket.socket) {
    staticLobbySocket.socket.close();
    setSocketLobbyId(null);
  }
  if (staticLobbySocket.lobbyUnsubscribe) {
    staticLobbySocket.lobbyUnsubscribe();
  }
  if (staticLobbySocket.lobbyChatUnsubscribe) {
    staticLobbySocket.lobbyChatUnsubscribe();
  }
  staticLobbySocket.lobbyUnsubscribe = false;
  staticLobbySocket.lobbyChatUnsubscribe = false;
  staticLobbySocket.socket = null;
};

export const unsubscribeFromPublicLobbiesRealtime = () => {
  if (staticPublicLobbiesSocket.publicLobbiesUnsubscribe) {
    staticPublicLobbiesSocket.publicLobbiesUnsubscribe();
  }
  staticPublicLobbiesSocket.publicLobbiesUnsubscribe = false;
};

export const subscribeToLobbyRealtime = (
  lobbyId,
  userUid,
  lobbyDataCb,
  chatCb,
  setSocketLobbyId
) => {
  if (!lobbyId || !userUid || !lobbyDataCb || !chatCb)
    throw new Error("No lobbyId or userUid, lobbyDataCb or chatCb provided!");

  initLobbySocket(lobbyId, userUid, setSocketLobbyId);
  initLobbySubscribe(lobbiesRef.doc(lobbyId).onSnapshot(lobbyDataCb));
  initLobbyChatSubscribe(
    lobbiesRef
      .doc(lobbyId)
      .collection("chat")
      .orderBy("timestamp", "desc")
      .limit(30)
      .onSnapshot(chatCb)
  );
};

export const subscribeToPublicLobbiesRealtime = (firestoreCb) => {
  if (!firestoreCb) throw new Error("No firestoreCb provided!");

  if (staticPublicLobbiesSocket.publicLobbiesUnsubscribe) {
    unsubscribeFromPublicLobbiesRealtime();
  }

  initPublicLobbySubscribe(
    lobbiesRef
      .where("visibility", "==", LOBBY_VISIBILITIES.PUBLIC)
      .where("status", "==", LOBBY_STATUSES.CREATED)
      .onSnapshot(firestoreCb)
  );
};
