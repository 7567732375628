import React from "react";

const LoadingFullScreen = () => {
  return (
    <div className="absolute inset-0 flex justify-center items-center">
      <img
        src="/esgplay/logotype.png"
        alt="ESGPLAY"
        width="100%"
        style={{ maxWidth: 320 }}
      />
    </div>
  );
};

export default LoadingFullScreen;
