import { PLAY_MODES } from "../lib";
import { lobbiesRef } from "../services/firebase";

export const staticMatchmakingSocket = {
  matchmakingUnsubscribe: null,
};

export const initMatchmakingSubscribe = (_matchmakingUnsubscribe) => {
  staticMatchmakingSocket.matchmakingUnsubscribe = _matchmakingUnsubscribe;
};

export const unsubscribeFromMatchmakingRealtime = () => {
  if (staticMatchmakingSocket.matchmakingUnsubscribe) {
    staticMatchmakingSocket.matchmakingUnsubscribe();
  }
  staticMatchmakingSocket.matchmakingUnsubscribe = false;
};

export const subscribeToMatchmakingRealtime = (firestoreCb) => {
  if (!firestoreCb) throw new Error("No firestoreCb provided!");

  if (staticMatchmakingSocket.matchmakingUnsubscribe) {
    unsubscribeFromMatchmakingRealtime();
  }

  initMatchmakingSubscribe(
    lobbiesRef
      .where("playMode", "in", [
        PLAY_MODES.AUTOMATIC,
        PLAY_MODES.AUTOMATIC_EPHEMERAL,
      ])
      .onSnapshot(firestoreCb)
  );
};
