import React, { lazy, Suspense, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter,
  useLocation,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import LoadingFullScreen from "./components/Shared/LoadingFullScreen";

import { UserStore } from "./stores";

const PlatformLayout = lazy(() => import("./views/layouts/PlatformLayout"));
const AdminLayout = lazy(() => import("./views/layouts/AdminLayout"));
const GuestLayout = lazy(() => import("./views/layouts/GuestLayout"));
const LandingLayout = lazy(() => import("./views/layouts/LandingLayout"));

const PlatformLayoutInstance = withRouter(PlatformLayout);
const AdminLayoutInstance = withRouter(AdminLayout);
const GuestLayoutInstance = withRouter(GuestLayout);
const LandingLayoutInstance = withRouter(LandingLayout);

const Routes = () => {
  const location = useLocation();
  React.useEffect(() => {
    if (window.gtag) {
      window.gtag("config", "UA-114561912-2", { page_path: location.pathname });
    }
  }, [location]);

  return (
    <Suspense fallback={<LoadingFullScreen />}>
      <Switch>
        <LandingRoute exact path="/" />
        <LandingRoute exact path="/home" />
        <LandingRoute exact path="/register" />
        <LandingRoute exact path="/anti-cheat" />
        <LandingRoute exact path="/about-us" />
        <LandingRoute exact path="/privacy-policy" />
        <LandingRoute exact path="/terms-of-use" />
        <GuestRoute exact path="/forgot" />
        <PlatformRoute exact guestAccessible path="/vip" />
        <PlatformRoute exact guestAccessible path="/changelog" />
        <PlatformRoute exact guestAccessible path="/ranking" />
        <PlatformRoute exact path="/verification" />
        <PlatformRoute exact guestAccessible path="/mvppreview" />
        <PlatformRoute exact path="/terms-of-use" />
        <PlatformRoute exact path="/privacy-policy" />
        <PlatformRoute exact path="/community-servers" />
        <PlatformRoute exact guestAccessible path="/bot/login/callback" />
        <PlatformRoute exact guestAccessible path="/bot/guild/callback" />
        <PlatformRoute guestAccessible path="/profiles/:uid" />
        <PlatformRoute exact path="/profiles" />
        <PlatformRoute exact path="/profile/settings" />
        <PlatformRoute path="/platform" />
        <PlatformRoute path="/lobby" />
        <PlatformRoute path="/join" />
        <PlatformRoute exact guestAccessible path="/tournaments" />
        <PlatformRoute exact guestAccessible path="/tournament/:tournamentId" />
        <PlatformRoute path="/tournament" />
        <PlatformRoute path="/tournaments" />
        <PlatformRoute guestAccessible path="/match/:matchId" />
        <AdminRoute path="/admin" />
        <LandingRoute />
      </Switch>
    </Suspense>
  );
};

// TODO Use React Strict Mode
const App = () => (
  <Router>
    <Routes />
  </Router>
);

const GuestRoute = observer(({ ...rest }) => {
  const userStore = useContext(UserStore);

  if (!userStore.thereIsUser) {
    return <Route {...rest} component={GuestLayoutInstance} />;
  }

  return (
    <Route
      {...rest}
      render={() => (
        <Redirect
          to={{
            pathname: "/platform",
          }}
        />
      )}
    />
  );
});

const PlatformRoute = observer(({ guestAccessible, ...rest }) => {
  const userStore = useContext(UserStore);

  if (userStore.thereIsUser) {
    return <Route {...rest} component={PlatformLayoutInstance} />;
  }
  if (guestAccessible) {
    return <Route {...rest} component={GuestLayoutInstance} />;
  }
  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )}
    />
  );
});

const AdminRoute = observer(({ ...rest }) => {
  const userStore = useContext(UserStore);

  if (userStore.userIsAdmin) {
    return <Route {...rest} component={AdminLayoutInstance} />;
  }
  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )}
    />
  );
});

const LandingRoute = observer(({ ...rest }) => {
  const userStore = useContext(UserStore);

  if (userStore.thereIsUser) {
    return <Route {...rest} component={PlatformLayoutInstance} />;
  }

  if (!userStore.thereIsUser) {
    return <Route {...rest} component={LandingLayoutInstance} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )}
    />
  );
});

export default App;
