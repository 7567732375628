import FontFaceObserver from "fontfaceobserver";

export default () => {
  const TitilliumWebRegular = new FontFaceObserver("Titillium Web", {
    weight: 400,
  });
  const TitilliumWebBold = new FontFaceObserver("Titillium Web", {
    weight: 700,
  });
  const FjallaOneRegular = new FontFaceObserver("Fjalla One", {
    weight: 400,
  });

  if (
    sessionStorage.bodyFontsLoaded &&
    sessionStorage.bodyFontsLoaded !== "false"
  ) {
    document.documentElement.classList.add("fonts-body-loaded");
  } else {
    Promise.all([TitilliumWebRegular.load(), TitilliumWebBold.load()])
      .then(() => {
        document.documentElement.classList.add("fonts-body-loaded");
        sessionStorage.bodyFontsLoaded = true;
      })
      .catch(() => {
        sessionStorage.bodyFontsLoaded = false;
      });
  }

  if (
    sessionStorage.headingFontsLoaded &&
    sessionStorage.headingFontsLoaded !== "false"
  ) {
    document.documentElement.classList.add("fonts-heading-loaded");
  } else {
    Promise.all([FjallaOneRegular.load()])
      .then(() => {
        document.documentElement.classList.add("fonts-heading-loaded");
        sessionStorage.headingFontsLoaded = true;
      })
      .catch(() => {
        sessionStorage.headingFontsLoaded = false;
      });
  }
};
