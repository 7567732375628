import { createContext } from "react";
import RootStoreClass from "./RootStore";

export const root = new RootStoreClass();

export const RootStore = createContext(root);
export const MetaStore = createContext(root.metaStore);
export const AlertStore = createContext(root.alertStore);
export const UserStore = createContext(root.userStore);
export const LobbyStore = createContext(root.lobbyStore);
export const MatchmakingStore = createContext(root.matchmakingStore);
export const MatchStore = createContext(root.matchStore);
export const TournamentStore = createContext(root.tournamentStore);
