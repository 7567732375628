import React from "react";
import ReactDOM from "react-dom";
import { configure } from "mobx";

import "./css/tailwind.css";

import App from "./App";
import { auth } from "./services/firebase";
import { root } from "./stores";
import { getUserByUid } from "./stores/UserStore";
import { getAppMeta } from "./stores/MetaStore";

import initFonts from "./fonts";

configure({
  enforceActions: "observed",
});

initFonts();

let painted;
auth.onAuthStateChanged(async (user) => {
  const { currentUser } = root.userStore;

  if (user && !(currentUser && currentUser.uid)) {
    const { uid } = user;
    try {
      const [userData, appMeta] = await Promise.all([
        getUserByUid(uid),
        getAppMeta(),
      ]);
      root.userStore.setUser({
        uid: user.uid,
        email: user.email,
        data: userData,
      });
      root.metaStore.setAppMeta(appMeta);
    } catch (e) {
      root.userStore.unsetUser();
    }
  }
  if (!painted) {
    ReactDOM.render(<App />, document.getElementById("root"));
    painted = true;
  }
});
