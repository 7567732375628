import { getItem, setItem } from "../services/localStorage";

export const PLAY_MODES = {
  CUSTOM: "CUSTOM",
  AUTOMATIC: "AUTOMATIC",
  AUTOMATIC_EPHEMERAL: "AUTOMATIC_EPHEMERAL",
  AUTOMATIC_STARTED: "AUTOMATIC_STARTED",
};

export const LOBBY_AUTOMATIC_STATUSES = {
  LOOKING_FOR_PLAYERS: "LOOKING_FOR_PLAYERS",
  CHECK_READY: "CHECK_READY",
  READY_SUCCEEDED: "READY_SUCCEEDED",
};

export const DEFAULT_PLAY_MODE = PLAY_MODES.CASUAL;

export const LOBBY_STATUSES = {
  CREATED: "CREATED",
  STARTED: "STARTED",
  FINISHED: "FINISHED",
};

export const GAME_STATUSES = {
  CREATED: "CREATED",
  STARTED: "STARTED",
  FINISHED: "FINISHED",
};

export const TOURNAMENT_LEVELS = {
  TOURNAMENT: "TOURNAMENT",
  ROUND: "ROUND",
  ENCOUNTER: "ENCOUNTER",
};

export const TOURNAMENT_ROLES = {
  OWNER: "OWNER",
  ADMIN: "ADMIN",
};

export const LOBBY_REGIONS = {
  PERU: "PERU",
  MIAMI: "MIAMI",
  CHILE: "CHILE",
  ARGENTINA: "ARGENTINA",
};

export const lobbyStatusToText = (status) => {
  switch (status) {
    case LOBBY_STATUSES.STARTED:
      return "EN CURSO";
    case LOBBY_STATUSES.FINISHED:
      return "FINALIZADO";
    default:
      return "ESPERANDO JUGADORES";
  }
};

export const get5GameStateToText = (state) => {
  switch (state) {
    case 0:
    case undefined:
      return "NONE";
    case 3:
      return "WARMUP";
    case 4:
      return "KNIFEROUND";
    case 5:
      return "KNIFEDECISION";
    case 6:
      return "LIVEON3";
    case 7:
      return "LIVE";
    case 8:
      return "POSTGAME";
    default:
      return "UNKNOWN";
  }
};

export const MATCH_MODES = {
  STANDARD: "STANDARD",
  CAPTAIN: "CAPTAIN",
};

export const LOBBY_VISIBILITIES = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
};

export const getLobbyTeamA = (lobbyData) => {
  if (!lobbyData || !lobbyData.players || lobbyData.players.length < 5)
    return [];
  return lobbyData.players.slice(0, 5);
};

export const getLobbyTeamB = (lobbyData) => {
  if (!lobbyData || !lobbyData.players || lobbyData.players.length < 10)
    return [];
  return lobbyData.players.slice(5, 10);
};

export const getLobbyHost = (lobbyData) => {
  if (!lobbyData) return null;

  const playerHost = lobbyData.players.find((p) => p.uid && p.isHost);
  if (playerHost) return playerHost;

  const spectatorHost = lobbyData.spectators.find(
    (s) => s && s.uid && s.isHost
  );
  if (spectatorHost) return spectatorHost;

  const coachAHost =
    lobbyData.coachTeamA && lobbyData.coachTeamA.isHost
      ? lobbyData.coachTeamA
      : null;
  if (coachAHost) return coachAHost;

  const coachBHost =
    lobbyData.coachTeamB && lobbyData.coachTeamB.isHost
      ? lobbyData.coachTeamB
      : null;
  if (coachBHost) return coachBHost;

  return null;
};

export const CSGO_MAPS = {
  ABBEY: {
    code: "de_abbey",
    name: "Abbey",
    isVip: true,
  },
  ANCIENT: {
    code: "de_ancient",
    name: "Ancient",
    isVip: false,
  },
  BIOME: {
    code: "de_biome",
    name: "Biome",
    isVip: true,
  },
  COBBLESTONE: {
    code: "de_cbble",
    name: "Cobblestone",
    isVip: true,
  },
  CACHE: {
    code: "de_cache",
    name: "Cache",
    isVip: true,
  },
  CANALS: {
    code: "de_canals",
    name: "Canals",
    isVip: true,
  },
  DUST2: {
    code: "de_dust2",
    name: "Dust 2",
    isVip: false,
  },
  INFERNO: {
    code: "de_inferno",
    name: "Inferno",
    isVip: false,
  },
  MIRAGE: {
    code: "de_mirage",
    name: "Mirage",
    isVip: false,
  },
  NUKE: {
    code: "de_nuke",
    name: "Nuke",
    isVip: false,
  },
  OVERPASS: {
    code: "de_overpass",
    name: "Overpass",
    isVip: false,
  },
  TRAIN: {
    code: "de_train",
    name: "Train",
    isVip: true,
  },
  VERTIGO: {
    code: "de_vertigo",
    name: "Vertigo",
    isVip: false,
  },
  ZOO: {
    code: "de_zoo",
    name: "Zoo",
    isVip: true,
  },
  ANUBIS: {
    code: "de_anubis",
    name: "Anubis",
    isVip: false,
  }
};

export const CSGO_TEAMS = {
  TEAM1: 0,
  TEAM2: 1,
};

export const CSGO_MAP_CODE_DIC = Object.fromEntries(
  Object.entries(CSGO_MAPS).map(([, m]) => [m.code, m.code])
);

export const DEFAULT_CSGO_MAP_POOL = [
  CSGO_MAPS.DUST2,
  CSGO_MAPS.TRAIN,
  CSGO_MAPS.MIRAGE,
  CSGO_MAPS.CACHE,
  CSGO_MAPS.NUKE,
  CSGO_MAPS.COBBLESTONE,
  CSGO_MAPS.INFERNO,
  CSGO_MAPS.CANALS,
  CSGO_MAPS.OVERPASS,
  CSGO_MAPS.ZOO,
  CSGO_MAPS.VERTIGO,
  CSGO_MAPS.ABBEY,
  CSGO_MAPS.ANCIENT,
  CSGO_MAPS.BIOME,
  CSGO_MAPS.ANUBIS
];

export const ACTIVE_DUTY_CSGO_MAP_POOL = [
  CSGO_MAPS.INFERNO,
  CSGO_MAPS.MIRAGE,
  CSGO_MAPS.NUKE,
  CSGO_MAPS.OVERPASS,
  CSGO_MAPS.ANUBIS,
  CSGO_MAPS.VERTIGO,
  CSGO_MAPS.ANCIENT,
];

export const DEFAULT_LOBBY_REGION = LOBBY_REGIONS.PERU;

export const PLAYER_COLORS = {
  BLUE: { key: "BLUE", hex: "#0000FF" },
  ORANGE: { key: "ORANGE", hex: "#FF6600" },
  GREEN: { key: "GREEN", hex: "#008000" },
  PURPLE: { key: "PURPLE", hex: "#800080" },
  YELLOW: { key: "YELLOW", hex: "#FEFF09" },
};

export const PLAYER_COLORS_ARRAY = Object.keys(PLAYER_COLORS);

export const countConnectedPlayers = (players) =>
  players.filter((p) => p.uid).length;

export const getPlayerSlotColor = (index) =>
  PLAYER_COLORS_ARRAY[index % PLAYER_COLORS_ARRAY.length];

export const getPlayerColorHex = (key) => {
  if (key === "") return "";
  if (key instanceof Object && key.hex) return key.hex;
  return PLAYER_COLORS[key] ? PLAYER_COLORS[key].hex : "";
};

export const DEFAULT_PLAYER_COLOR = PLAYER_COLORS.BLUE.key;

export const getDefaultLobbyRegion = (regions) => {
  if (!regions || !Array.isArray(regions)) return DEFAULT_LOBBY_REGION;

  const baseRegion = getItem("lobby_region");

  if (baseRegion && regions.indexOf(baseRegion) !== -1) {
    return baseRegion;
  } else {
    if (baseRegion) {
      setItem("lobby_region", null);
    }
    return regions.indexOf(DEFAULT_LOBBY_REGION) !== -1
      ? DEFAULT_LOBBY_REGION
      : null;
  }
};

export const getLobbyRegionToString = (region) => {
  switch (region) {
    case LOBBY_REGIONS.PERU:
      return "Perú";
    case LOBBY_REGIONS.MIAMI:
      return "Miami";
    case LOBBY_REGIONS.CHILE:
      return "Chile";
    case LOBBY_REGIONS.ARGENTINA:
      return "Argentina";
    default:
      return "";
  }
};

export const getDefaultMatchmakingLobbySetup = (regions) => ({
  region: getDefaultLobbyRegion(regions),
  mapCode: ACTIVE_DUTY_CSGO_MAP_POOL[0].code,
  teamAName: "TEAM A",
  teamBName: "TEAM B",
});

export const getDefaultLobbySetup = (regions) => ({
  visibility: LOBBY_VISIBILITIES.PRIVATE,
  region: getDefaultLobbyRegion(regions),
  coachesEnabled: false,
  spectatorsEnabled: false,
  gotvEnabled: true,
  backupsEnabled: false,
  acEnabled: false,
  verificationEnabled: false,
});

export const TOURNAMENT_STATUSES = {
  CREATED: "CREATED",
  STARTED: "STARTED",
  FINISHED: "FINISHED",
};

export const TOURNAMENT_SIZES = [8, 16];

export const ENCOUNTER_TYPES = {
  DUEL: "DUEL",
  FFA: "FFA",
};

export const MATCH_TYPES = {
  SIMPLE: "SIMPLE",
  BO3: "BO3",
  BO5: "BO5",
};

export const GAMES = {
  CSGO: "CS:GO",
  DOTA: "Dota 2",
  LOL: "League of Legends",
  PUBG: "PUBG",
};

export const CSGO_SIDES = {
  CT: "CT", // Also is used as a key for 'Team A'
  T: "T", // Also is used as a key for 'Team B'
};

export const TEAM_SIZE = 5;
export const MAX_NUMBER_OF_SPECTATORS = 3;

export const LOBBY_PERSONAS = {
  PLAYER: "PLAYER",
  COACH: "COACH",
  SPECTATOR: "SPECTATOR",
};

export const getSlotFromLobby = (lobbyData, userUid) => {
  const { players, coachTeamA, coachTeamB, spectators } = lobbyData;

  const playerIndex = players
    ? players.findIndex((p) => p.uid === userUid)
    : null;
  if (playerIndex !== -1) {
    return {
      index: playerIndex,
      persona: LOBBY_PERSONAS.PLAYER,
      data: players[playerIndex],
    };
  }

  if (coachTeamA && coachTeamA.uid === userUid) {
    return {
      team: CSGO_SIDES.CT,
      persona: LOBBY_PERSONAS.COACH,
      data: coachTeamA,
    };
  }

  if (coachTeamB && coachTeamB.uid === userUid) {
    return {
      team: CSGO_SIDES.T,
      persona: LOBBY_PERSONAS.COACH,
      data: coachTeamB,
    };
  }

  const spectatorIndex = spectators
    ? spectators.findIndex((s) => (s ? s.uid === userUid : false))
    : null;
  if (spectatorIndex !== -1) {
    return {
      index: spectatorIndex,
      persona: LOBBY_PERSONAS.SPECTATOR,
      data: spectators[spectatorIndex],
    };
  }
  return null;
};

export const matchTypeToNumberOfGames = (matchType) => {
  switch (matchType) {
    case MATCH_TYPES.BO5:
      return 5;
    case MATCH_TYPES.BO3:
      return 3;
    case MATCH_TYPES.SIMPLE:
    default:
      return 1;
  }
};
