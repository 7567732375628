import { action, computed, decorate, observable } from "mobx";

import { metaRef } from "../services/firebase";

const META_COLLECTION_DOCS = {
  appMeta: "AppMeta",
};

const TIME_TO_HIDE_ANNOUNCEMENT = 6 * 24 * 60 * 60 * 1000;

const getDefaultAppMeta = () => ({
  latestUpdateTimestamp: null,
  regions: [],
});

export const getAppMeta = async () => {
  const appMetaQuerySnapshot = await metaRef
    .doc(META_COLLECTION_DOCS.appMeta)
    .get();

  return appMetaQuerySnapshot.data() || getDefaultAppMeta();
};

export default class MetaStoreClass {
  appMeta = getDefaultAppMeta();

  constructor({ root }) {
    this.root = root;
  }

  get latestUpdateAnnouncementIsVisible() {
    if (!this.appMeta.latestUpdateTimestamp) return false;

    if (
      localStorage.getItem("latestUpdateTimestamp") ===
      this.appMeta.latestUpdateTimestamp.toString()
    )
      return false;

    return (
      Date.now() - this.appMeta.latestUpdateTimestamp <
      TIME_TO_HIDE_ANNOUNCEMENT
    );
  }

  setAppMeta(_appMeta) {
    this.appMeta = _appMeta;
  }

  dismissLatestUpdateAnnouncement() {
    localStorage.setItem(
      "latestUpdateTimestamp",
      this.appMeta.latestUpdateTimestamp
    );
    this.appMeta.latestUpdateTimestamp = null;
  }
}

decorate(MetaStoreClass, {
  appMeta: observable,
  latestUpdateAnnouncementIsVisible: computed,
  setAppMeta: action,
  dismissLatestUpdateAnnouncement: action,
});
