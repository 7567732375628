import { usersRef } from "../services/firebase";

export const staticCurrentUserSocket = {
  currentUserUnsubscribe: null,
};

export const initCurrentUserSubscribe = (_currentUserUnsubscribe) => {
  staticCurrentUserSocket.currentUserUnsubscribe = _currentUserUnsubscribe;
};

export const unsubscribeFromCurrentUserRealtime = () => {
  if (staticCurrentUserSocket.currentUserUnsubscribe) {
    staticCurrentUserSocket.currentUserUnsubscribe();
  }
  staticCurrentUserSocket.currentUserUnsubscribe = false;
};

export const subscribeToCurrentUserRealtime = (userUid, currentUserDataCb) => {
  if (!userUid || !currentUserDataCb)
    throw new Error("No userUid, currentUserDataCb provided!");

  if (staticCurrentUserSocket.currentUserUnsubscribe) {
    unsubscribeFromCurrentUserRealtime();
  }

  initCurrentUserSubscribe(usersRef.doc(userUid).onSnapshot(currentUserDataCb));
};
