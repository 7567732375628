import { action, decorate, observable } from "mobx";

export default class AlertStoreClass {
  constructor({ root }) {
    this.lobbyFull = false;
    this.lobbyNotFound = false;
    this.userMatchmakingDisabled = false;
    this.lobbyAlreadyStarted = false;
    this.personaInOtherLobby = false;
    this.notVipMatchLimit = false;
    this.notVerified = false;
    this.root = root;
  }

  setLobbyFull(value) {
    this.lobbyFull = value;
  }

  setLobbyNotFound(value) {
    this.lobbyNotFound = value;
  }

  setUserMatchmakingDisabled(value) {
    this.userMatchmakingDisabled = value;
  }

  setLobbyAlreadyStarted(value) {
    this.lobbyAlreadyStarted = value;
  }

  setPersonaInOtherLobby(value) {
    this.personaInOtherLobby = value;
  }

  setNotVipMatchLimit(value) {
    this.notVipMatchLimit = value;
  }

  setNotVerified(value) {
    this.notVerified = value;
  }
}

decorate(AlertStoreClass, {
  lobbyFull: observable,
  lobbyNotFound: observable,
  userMatchmakingDisabled: observable,
  lobbyAlreadyStarted: observable,
  personaInOtherLobby: observable,
  notVipMatchLimit: observable,
  notVerified: observable,
  setLobbyFull: action,
  setLobbyNotFound: action,
  setUserMatchmakingDisabled: action,
  setLobbyAlreadyStarted: action,
  setPersonaInOtherLobby: action,
  setNotVipMatchLimit: action,
  setNotVerified: action,
});
