import { action, computed, decorate, observable, runInAction } from "mobx";
import throttle from "lodash.throttle";
import get from "lodash.get";

import { lobbiesRef } from "../services/firebase";
import { request } from "../services/requests";
import {
  subscribeToLobbyRealtime,
  unsubscribeFromLobbyRealtime,
  subscribeToPublicLobbiesRealtime,
  staticLobbySocket,
  unsubscribeFromPublicLobbiesRealtime,
} from "../realtime/lobby";

import {
  getLobbyTeamA,
  getLobbyTeamB,
  LOBBY_STATUSES,
  PLAY_MODES,
} from "../lib";
import { playMatchReady } from "../lib/audio";

const getEmptyLobby = () => ({
  data: null,
  id: null,
  isLoading: false,
  isHidden: true,
  setting: false,
  matchmakingSetting: false,
  widgetPanelIndex: 2,
  failureAtLoading: false,
  chat: [],
});

export const getLobbyById = async (uid) => {
  const lobbySnapshot = await lobbiesRef.doc(uid).get();
  if (lobbySnapshot.exists) return lobbySnapshot.data();
  return null;
};

const getWidgetPanelByLobbyVisibility = (visibility) => {
  if (visibility) {
    return 2;
  }
  return 0;
};

const throttledChangeLobbyIsHidden = throttle(
  (store, value) => {
    runInAction(() => {
      store.currentLobby = {
        ...store.currentLobby,
        widgetPanelIndex: getWidgetPanelByLobbyVisibility(!value),
        isHidden: value,
      };
    });
  },
  600,
  { trailing: false }
);

export default class LobbyStoreClass {
  currentLobby = getEmptyLobby();

  publicLobbies = [];

  lobbyWasDeleted = false;

  constructor({ root }) {
    this.root = root;
  }

  get thereIsLobby() {
    return Boolean(this.currentLobby.id && this.currentLobby.data);
  }

  get teamA() {
    if (!this.thereIsLobby) return [];
    return getLobbyTeamA(this.currentLobby.data);
  }

  get teamB() {
    if (!this.thereIsLobby) return [];
    return getLobbyTeamB(this.currentLobby.data);
  }

  get shareLobbyUrl() {
    const { location } = window;
    if (!this.thereIsLobby) return null;
    const sharePrefix = `${location.protocol}//${location.host}`;

    // We use different link for AUTOMATIC lobbies since they should be redirected to PLATFORM HOME, and not LOBBY HOME.
    if (
      this.currentLobby.data.playMode === PLAY_MODES.AUTOMATIC ||
      this.currentLobby.data.playMode === PLAY_MODES.AUTOMATIC_EPHEMERAL
    ) {
      return `${sharePrefix}/join/${this.currentLobby.id}`;
    }

    return `${sharePrefix}/lobby/${this.currentLobby.id}`;
  }

  startLoading() {
    this.currentLobby.isLoading = true;
  }

  stopLoading() {
    this.currentLobby.isLoading = false;
  }

  startMatchmakingSetting() {
    this.currentLobby.matchmakingSetting = true;
  }

  startSetting() {
    this.currentLobby.setting = true;
  }

  finishSetting() {
    this.currentLobby.matchmakingSetting = false;
    this.currentLobby.setting = false;
  }

  hideLobby() {
    throttledChangeLobbyIsHidden(this, true);
  }

  showLobby() {
    throttledChangeLobbyIsHidden(this, false);
  }

  toggleLobby() {
    throttledChangeLobbyIsHidden(this, !this.currentLobby.isHidden);
  }

  changeWidgetPanel(index) {
    if (index !== this.currentLobby.widgetPanelIndex) {
      this.currentLobby.widgetPanelIndex = index;

      // Also show the lobby if the new panel is the Chat Panel.
      // TODO: Change this hardcoded index to key identifier.
      if (index === 2) {
        this.showLobby();
      }
    }
  }

  setFailureAtLoading(value) {
    this.currentLobby.failureAtLoading = value;
  }

  setLobbyWasDeleted(value) {
    this.lobbyWasDeleted = value;
  }

  setDisAddress(newAddress) {
    this.currentLobby.data.disAddress = newAddress;
  }

  async updateMap(mapCode) {
    if (!this.thereIsLobby || this.currentLobby.isLoading || !mapCode) return;
    this.startLoading();

    await lobbiesRef
      .doc(this.currentLobby.id)
      .update({
        mapCode,
        updateTimestamp: Date.now(),
      })
      .finally(() => this.stopLoading());
  }

  async updateTeamAName(name) {
    if (!this.thereIsLobby || !name) return;

    await lobbiesRef.doc(this.currentLobby.id).update({
      teamA: {
        name,
      },
      updateTimestamp: Date.now(),
    });
  }

  async updateTeamBName(name) {
    if (!this.thereIsLobby || !name) return;

    await lobbiesRef.doc(this.currentLobby.id).update({
      teamB: {
        name,
      },
      updateTimestamp: Date.now(),
    });
  }

  async enterMessageToChat(userUid, nickname, avatar, message, isVip) {
    if (!userUid || !nickname || !avatar || !message || !this.thereIsLobby)
      return;

    await request.post(`/play/lobby/custom/${this.currentLobby.id}/chat`, {
      userUid,
      nickname,
      avatar,
      message,
      isVip,
    });
  }

  async movePlayerToPlayerSlot(userUid, index) {
    if (!userUid || !this.thereIsLobby || this.currentLobby.isLoading) return;
    this.startLoading();
    await request
      .post(`/play/lobby/custom/${this.currentLobby.id}/slot/player/update`, {
        userUid,
        index,
      })
      .finally(() => this.stopLoading());
  }

  async movePlayerToCoachSlot(userUid, team) {
    if (!userUid || !this.thereIsLobby || this.currentLobby.isLoading) return;
    this.startLoading();
    await request
      .post(`/play/lobby/custom/${this.currentLobby.id}/slot/coach/update`, {
        userUid,
        team,
      })
      .finally(() => this.stopLoading());
  }

  async movePlayerToSpectatorSlot(userUid, index) {
    if (!userUid || !this.thereIsLobby || this.currentLobby.isLoading) return;
    this.startLoading();
    await request
      .post(
        `/play/lobby/custom/${this.currentLobby.id}/slot/spectator/update`,
        {
          userUid,
          index,
        }
      )
      .finally(() => this.stopLoading());
  }

  async deletePersonaFromLobby(userUid, nickname) {
    if (!userUid || !nickname || !this.thereIsLobby) return;

    this.startLoading();
    await request
      .post(`/play/lobby/custom/${this.currentLobby.id}/delete/${userUid}`, {
        nickname,
      })
      .finally(() => this.stopLoading());
  }

  async leaveLobby(userUid, nickname, setSocketLobbyId) {
    if (!userUid || !nickname || !this.thereIsLobby) return;

    this.startLoading();

    try {
      await request.post(
        `/play/lobby/custom/${this.currentLobby.id}/slot/leave`,
        {
          userUid,
          nickname,
        }
      );
      this.unsubscribeFromLobby(setSocketLobbyId);
      this.stopLoading();
    } catch (e) {
      this.stopLoading();
      throw e;
    }
  }

  async deleteLobby(userUid, setSocketLobbyId) {
    if (!userUid || !this.thereIsLobby) return;

    this.startLoading();

    try {
      await request.post(`/play/lobby/custom/${this.currentLobby.id}/delete`, {
        userUid,
      });
      this.unsubscribeFromLobby(setSocketLobbyId);
      this.stopLoading();
    } catch (e) {
      this.stopLoading();
      throw e;
    }
  }

  subscribeToLobby(lobbyId, userUid, setSocketLobbyId) {
    if (!lobbyId || !userUid) return;

    // Redundancy validation.
    if (staticLobbySocket.lobbyUnsubscribe) {
      this.stopLoading();
      this.finishSetting();
      return;
    }

    let firstTime = true;
    subscribeToLobbyRealtime(
      lobbyId,
      userUid,
      /**
       *
       * @param {firebase.firestore.DocumentSnapshot} doc
       */
      (doc) => {
        runInAction(() => {
          const data = doc.data();

          if (!firstTime) {
            if (get(data, "status") === LOBBY_STATUSES.STARTED) {
              playMatchReady();
            }
          }

          this.currentLobby = {
            ...this.currentLobby,
            id: lobbyId,
            data,
          };

          // When we connect to lobby via firestore for the firs time.
          // We should open the lobby As feedback for the user.
          // This should happen for both lobby creator and lobby invitees.
          if (firstTime) {
            this.showLobby();

            // If for some reason user is in lobby setting UI, close setting UI.
            // Not closing Lobby setup UI will prevent to show the lobby.
            if (
              this.currentLobby.setting ||
              this.currentLobby.matchmakingSetting
            ) {
              this.stopLoading();
              this.finishSetting();
            }

            firstTime = false;
          }

          if (!doc.exists) {
            this.hideLobby();
            this.unsubscribeFromLobby(setSocketLobbyId);
          }
        });
      },
      /**
       *
       * @param {firebase.firestore.QuerySnapshot} query
       */
      (query) => {
        runInAction(() => {
          this.currentLobby.chat = query.docs.reverse().map((d) => ({
            id: d.id,
            data: d.data(),
          }));
        });
      },
      setSocketLobbyId
    );
  }

  unsubscribeFromLobby(setSocketLobbyId) {
    if (this.thereIsLobby) {
      unsubscribeFromLobbyRealtime(setSocketLobbyId);
      this.currentLobby = getEmptyLobby();
    }
  }

  subscribeToPublicLobbies() {
    subscribeToPublicLobbiesRealtime((query) => {
      runInAction(() => {
        this.publicLobbies = query.docs.map((d) => ({
          id: d.id,
          data: d.data(),
        }));
      });
    });
  }

  unsubscribeFromPublicLobbies() {
    unsubscribeFromPublicLobbiesRealtime();
    this.publicLobbies = [];
  }
}

decorate(LobbyStoreClass, {
  currentLobby: observable,
  publicLobbies: observable,
  thereIsLobby: computed,
  teamA: computed,
  teamB: computed,
  shareLobbyUrl: computed,
  startLoading: action,
  stopLoading: action,
  startMatchmakingSetting: action,
  startSetting: action,
  finishSetting: action,
  hideLobby: action,
  showLobby: action,
  toggleLobby: action,
  changeWidgetPanel: action,
  setFailureAtLoading: action,
  setLobbyWasDeleted: action,
  setDisAddress: action,
  updateMap: action,
  updateTeamAName: action,
  updateTeamBName: action,
  enterMessageToChat: action,
  movePlayerToPlayerSlot: action,
  movePlayerToCoachSlot: action,
  movePlayerToSpectatorSlot: action,
  deletePersonaFromLobby: action,
  deleteLobby: action,
  subscribeToLobby: action,
  unsubscribeFromLobby: action,
  subscribeToPublicLobbies: action,
  unsubscribeFromPublicLobbies: action,
});
