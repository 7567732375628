import { action, decorate, observable, runInAction } from "mobx";

import { request } from "../services/requests";

export default class MatchStoreClass {
  constructor({ root }) {
    this.lastMatchesFetched = false;
    this.lastMatches = [];
    this.root = root;
  }

  async fetchLastMatches() {
    request
      .get("/play/match/latest")
      .then((response) => {
        runInAction(() => {
          this.lastMatches = response.data.matches;
          this.lastMatchesFetched = true;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.lastMatchesFetched = true;
        });
      });
  }
}

decorate(MatchStoreClass, {
  lastMatchesFetched: observable,
  lastMatches: observable,
  fetchMatchStore: action,
});
