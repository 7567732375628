import { decorate, computed } from "mobx";

import MetaStoreClass from "./MetaStore";
import AlertStoreClass from "./AlertStore";
import UserStoreClass from "./UserStore";
import LobbyStoreClass from "./LobbyStore";
import MatchmakingStoreClass from "./MatchmakingStore";
import MatchStoreClass from "./MatchStore";
import TournamentStoreClass from "./TournamentStore";

import { getSlotFromLobby } from "../lib";

export default class RootStoreClass {
  constructor() {
    this.metaStore = new MetaStoreClass({ root: this });
    this.alertStore = new AlertStoreClass({ root: this });
    this.lobbyStore = new LobbyStoreClass({ root: this });
    this.matchmakingStore = new MatchmakingStoreClass({ root: this });
    this.userStore = new UserStoreClass({ root: this });
    this.matchStore = new MatchStoreClass({ root: this });
    this.tournamentStore = new TournamentStoreClass({ root: this });
  }

  get userIsLobbyHost() {
    if (!this.userStore.thereIsUser || !this.lobbyStore.thereIsLobby)
      return false;

    const slot = getSlotFromLobby(
      this.lobbyStore.currentLobby.data,
      this.userStore.currentUser.uid
    );
    if (slot && slot.data.isHost) return true;

    return false;
  }
}

decorate(RootStoreClass, {
  userIsLobbyHost: computed,
});
