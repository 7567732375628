import { action, decorate, observable, runInAction } from "mobx";

import {
  unsubscribeFromMatchmakingRealtime,
  subscribeToMatchmakingRealtime,
} from "../realtime/matchmaking";

export default class MatchmakingStoreClass {
  parties = [];

  constructor({ root }) {
    this.root = root;
  }

  subscribeToMatchmaking() {
    subscribeToMatchmakingRealtime((query) => {
      runInAction(() => {
        this.parties = query.docs.map((d) => ({
          id: d.id,
          data: d.data(),
        }));
      });
    });
  }

  unsubscribeFromMatchmaking() {
    unsubscribeFromMatchmakingRealtime();
    this.parties = [];
  }
}

decorate(MatchmakingStoreClass, {
  parties: observable,
  subscribeToMatchmaking: action,
  unsubscribeFromMatchmaking: action,
});
