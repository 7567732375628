import { action, computed, decorate, observable, runInAction } from "mobx";

import { usersRef, auth } from "../services/firebase";
import {
  unsubscribeFromLobbyRealtime,
  unsubscribeFromPublicLobbiesRealtime,
} from "../realtime/lobby";
import {
  subscribeToCurrentUserRealtime,
  unsubscribeFromCurrentUserRealtime,
} from "../realtime/user";

const getEmptyUser = () => ({
  uid: null,
  email: null,
  data: null,
});

export const getUserByUid = async (uid) => {
  const userSnapshot = await usersRef.doc(uid).get();
  if (userSnapshot.exists) return userSnapshot.data();
  return null;
};

export default class UserStoreClass {
  constructor({ root }) {
    this.currentUser = getEmptyUser();
    this.root = root;
  }

  get thereIsUser() {
    return !!this.currentUser.uid;
  }

  get userIsAdmin() {
    if (!this.thereIsUser) return false;
    return this.currentUser.data.isAdmin;
  }

  get userIsTester() {
    if (!this.thereIsUser) return false;
    return this.currentUser.data.isTester;
  }

  get userIsVip() {
    if (!this.thereIsUser) return false;
    return this.currentUser.data.isVip;
  }

  get userIsVerified() {
    if (!this.thereIsUser) return false;
    return this.currentUser.data.isVerified;
  }

  setUser(newUser) {
    this.currentUser = newUser;
  }

  unsetUser() {
    this.currentUser = getEmptyUser();
  }

  unsetActivePlay() {
    if (this.thereIsUser && this.currentUser.data) {
      this.currentUser = {
        ...this.currentUser,
        data: {
          ...this.currentUser.data,
          activePlay: null,
        },
      };
    }
  }

  async getIdToken() {
    const idToken = await auth.currentUser.getIdToken();
    return idToken;
  }

  subscribeToCurrentUser(userUid) {
    if (!userUid) return;

    subscribeToCurrentUserRealtime(userUid, (doc) => {
      runInAction(() => {
        const data = doc.data();
        this.currentUser = {
          ...this.currentUser,
          uid: userUid,
          data,
        };
      });
    });
  }

  unsubscribeFromCurrentUser() {
    if (this.thereIsUser) {
      unsubscribeFromCurrentUserRealtime();
    }
  }

  logout(setSocketLobbyId) {
    unsubscribeFromLobbyRealtime(setSocketLobbyId);
    unsubscribeFromPublicLobbiesRealtime();
    this.unsubscribeFromCurrentUser();
    this.unsetUser();
    auth.signOut();
  }
}

decorate(UserStoreClass, {
  currentUser: observable,
  thereIsUser: computed,
  userIsAdmin: computed,
  userIsTester: computed,
  setUser: action,
  unsetUser: action,
  unsetActivePlay: action,
  subscribeToCurrentUser: action,
  unsubscribeFromCurrentUser: action,
  logout: action,
});
