import { decorate, observable } from "mobx";

export default class TournamentStoreClass {
  constructor({ root }) {
    this.selectedTournament = null;
    this.selectedTournamentUpperLevel = null;
    this.selectedTournamentLevel = null;
    this.selectedLevelType = null;
    this.root = root;
  }
}

decorate(TournamentStoreClass, {
  selectedTournament: observable,
  selectedTournamentUpperLevel: observable,
  selectedTournamentLevel: observable,
  selectedLevelType: observable,
});
