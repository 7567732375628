import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyCxC5H86YxCH4-QLcIsfTujXZYsqpXeAVA",
  authDomain: "esgplaydb-test.firebaseapp.com",
  databaseURL: "https://esgplaydb-test.firebaseio.com",
  projectId: "esgplaydb-test",
  storageBucket: "esgplaydb-test.appspot.com",
  messagingSenderId: "1038602012291",
};
const firebaseApp = firebase.initializeApp(config);

export const auth = firebaseApp.auth();
export const firestore = firebaseApp.firestore();
export const storage = firebaseApp.storage("gs://esgplay-demos");

export const metaRef = firestore.collection("meta");
export const usersRef = firestore.collection("users");
export const lobbiesRef = firestore.collection("lobbies");
export const matchmakingRef = firestore.collection("matchmaking");

export default firebaseApp;
